<template>
  <div class="d-flex flex-column flex-root" data-app>
    <demo-mode/>
    <div
      class="
        login login-1
        d-flex
        flex-column flex-lg-row flex-column-fluid
        bg-white
      "
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-signup-on': this.state == 'signup',
        'login-forgot-on': this.state == 'forgot',
      }"
      id="kt_login"
    >
      <v-row wrap>
        <v-col align="center" cols="12" sm="12" lg="6" md="6" style="padding-bottom:0">
          <div
            style="
              display: flex;
              width: 100%;
              position: absolute;
              z-index: 15;
              margin-top: 10px;
            "
          >
            <img
              src="media/logos/jci-india-logo.png"
              style="margin: auto; z-index: 10; max-width: 26%"
              alt
            />
          </div>
          <login-left-column />

          <!--begin::Aside-->
          <!-- <div
              class="login-aside d-flex flex-column flex-row-auto"
              style="
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
              "
              v-bind:style="{ 'background-image': `url(${LoginLeftBgImage})` }"
            >
              <div class="d-flex flex-column-auto flex-column pt-lg-10">
                <p></p>
                <p />
                <a href="#" class="text-center mb-10">
                  <img src="media/logos/jci-india-logo.png" class="max-h-70px" alt />
                </a>
              </div>
              <div
                class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
              ></div>
          </div>-->
          <!--begin::Aside-->
          <!--begin::Content-->
        </v-col>
        <v-col cols="12" sm="6" lg="6" md="6" style="padding-bottom:0">
          <div
            class="
              login-content
              flex-row-fluid
              d-flex
              flex-column
              justify-content-center
              position-relative
              overflow-hidden
              p-7
            "
            style="background-repeat: repeat; background-color: white"
          >
            <!-- v-bind:style="{ 'background-image': `url(${LoginRightBgImage})` }" -->
            <div class="d-flex flex-column-fluid flex-center">
              <!--begin::Signin-->
              <div class="login-form login-signin">
                <!-- <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signin_form"
                >-->
                <v-form
                  v-on:submit.prevent="validateLogin"
                  v
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >
                  <div class="pb-8 pt-lg-0 pt-5">
                    <h3
                      class="
                        font-weight-bolder
                        text-dark
                        font-size-h4 font-size-h1-lg
                      "
                    >
                      WELCOME
                      <br />to JCI India Members Portal
                    </h3>
                  </div>
                  <marquee
                    class="text-primary"
                    width="100%"
                    direction="left"
                    scrollamount="2"
                    loop="infinite"
                  >
                    <h6 v-html="News"></h6>
                  </marquee>
                  <span class="text-dark font-weight-bold font-size-h4"
                    >Login Here</span
                  >
                  <p></p>
                  <div class="form-group">
                    <label class="font-size-h6 font-weight-bolder text-dark"
                      >Username</label
                    >
                    <div
                      id="example-input-group-1"
                      label
                      label-for="example-input-1"
                    >
                      <!-- <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="text"
                    name="email"
                    ref="email"
                    v-model="form.email"
                      />-->
                      <v-text-field
                        v-model="userLoginName"
                        :rules="userLoginNameRules"
                        :success="userLoginNameFlag"
                        val-icon-success="done"
                        hint="Enter your username"
                        background-color="#F3F6F9"
                        type="text"
                        prepend-inner-icon="mdi-account"
                        validate-on-blur
                        rounded
                        solo
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="d-flex justify-content-between mt-n5">
                      <label
                        class="font-size-h6 font-weight-bolder text-dark pt-5"
                        >Password</label
                      >
                      <!-- <a
                    class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                    id="kt_login_forgot"
                    @click="showForm('forgot')"
                    >Forgot Password ?</a
                      >-->
                    </div>
                    <div
                      id="example-input-group-2"
                      label
                      label-for="example-input-2"
                    >
                      <!-- <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="password"
                    name="password"
                    ref="password"
                    v-model="form.password"
                    autocomplete="off"
                      />-->
                      <v-text-field
                        v-model="userLoginPassword"
                        :rules="userLoginPasswordRules"
                        :success="userLoginPasswordFlag"
                        val-icon-success="done"
                        hint="Enter your password"
                        background-color="#F3F6F9"
                        validate-on-blur
                        rounded
                        solo
                        :type="isPasswordVisible ? 'text' : 'password'"
                        prepend-inner-icon="mdi-lock"
                        :append-icon="isPasswordVisible ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                        @click:append="isPasswordVisible = !isPasswordVisible"
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="pb-lg-0 pb-5">
                    <!-- <button
                  ref="kt_login_signin_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                >
                  Sign In
                    </button>-->
                    <v-checkbox
                      v-model="checkbox1"
                      :rules="checkboxRules1"
                      required
                    >
                      <template v-slot:label>
                        <div style="font-size: 14px">
                          I agree to the
                          <a href="#" @click.prevent="openPage(3)"
                            >privacy policy</a
                          >
                          and
                          <a href="#" @click.prevent="openPage(4)"
                            >terms and conditions</a
                          >.
                        </div>
                      </template>
                    </v-checkbox>
                    <v-btn
                      type="submit"
                      @click.prevent="validateLogin"
                      elevation="30"
                      :loading="submitFlag"
                      shaped
                      tile
                      large
                      color="#8950FC"
                      class="
                        btn btn-primary
                        font-size-h6
                        py-4
                        my-3
                        mr-3
                        text-white
                      "
                    >
                      <i class="fa fa-lock" style="color: #ffffff"></i>
                      Login
                    </v-btn>
                    <v-btn
                      @click.prevent="openFeaturePage(2)"
                      elevation="30"
                      shaped
                      tile
                      large
                      color="#0f9f59"
                      class="
                        btn btn-primary
                        font-size-h6
                        py-4
                        my-3
                        mr-3
                        text-white
                      "
                    >
                      <i class="fa fa-lock-open" style="color: #ffffff"></i>
                      Activate Account
                    </v-btn>
                    <!-- <button
                  type="button"
                  class="btn btn-light-primary font-weight-bolder px-8 py-4 my-3 font-size-lg"
                >
                  <span class="svg-icon svg-icon-md">
                    <inline-svg
                      src="media/svg/social-icons/google.svg"
                    /> </span
                  >Sign in with Google
                    </button>-->
                  </div>
                </v-form>
                <!-- </form> -->
              </div>
              <!--end::Signin-->
              <!--begin::Signup-->
              <div class="login-form login-signup">
                <form
                  class="form"
                  novalidate="novalidate"
                  id="kt_login_signup_form"
                >
                  <div class="pb-13 pt-lg-0 pt-5">
                    <h3
                      class="
                        font-weight-bolder
                        text-dark
                        font-size-h4 font-size-h1-lg
                      "
                    >
                      Sign Up
                    </h3>
                    <p class="text-muted font-weight-bold font-size-h4">
                      Enter your details to create your account
                    </p>
                  </div>
                  <div class="form-group">
                    <input
                      class="
                        form-control form-control-solid
                        h-auto
                        py-7
                        px-6
                        rounded-lg
                        font-size-h6
                      "
                      type="text"
                      placeholder="Fullname"
                      name="fullname"
                      ref="fullname"
                      autocomplete="off"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      class="
                        form-control form-control-solid
                        h-auto
                        py-7
                        px-6
                        rounded-lg
                        font-size-h6
                      "
                      type="email"
                      placeholder="Email"
                      name="email"
                      ref="remail"
                      autocomplete="off"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      class="
                        form-control form-control-solid
                        h-auto
                        py-7
                        px-6
                        rounded-lg
                        font-size-h6
                      "
                      type="password"
                      placeholder="Password"
                      name="password"
                      ref="rpassword"
                      autocomplete="off"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      class="
                        form-control form-control-solid
                        h-auto
                        py-7
                        px-6
                        rounded-lg
                        font-size-h6
                      "
                      type="password"
                      placeholder="Confirm password"
                      name="cpassword"
                      ref="cpassword"
                      autocomplete="off"
                    />
                  </div>
                  <div class="form-group">
                    <label class="checkbox mb-0">
                      <input type="checkbox" name="agree" />
                      <span class="mr-2"></span>
                      I Agree the
                      <a href="#" class="ml-2">terms and conditions</a>.
                    </label>
                  </div>
                  <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
                    <button
                      ref="kt_login_signup_submit"
                      class="
                        btn btn-primary
                        font-weight-bolder font-size-h6
                        px-8
                        py-4
                        my-3
                        mr-4
                      "
                      style="width: 150px"
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      id="kt_login_signup_cancel"
                      class="
                        btn btn-light-primary
                        font-weight-bolder font-size-h6
                        px-8
                        py-4
                        my-3
                      "
                      @click="showForm('signin')"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
              <!--end::Signup-->
              <!--begin::Forgot-->
              <div class="login-form login-forgot">
                <!--begin::Form-->
                <form
                  class="form"
                  novalidate="novalidate"
                  id="kt_login_forgot_form"
                  ref="kt_login_forgot_form"
                >
                  <div class="pb-13 pt-lg-0 pt-5">
                    <h3
                      class="
                        font-weight-bolder
                        text-dark
                        font-size-h4 font-size-h1-lg
                      "
                    >
                      Forgotten Password ?
                    </h3>
                    <p class="text-muted font-weight-bold font-size-h4">
                      Enter your email to reset your password
                    </p>
                  </div>
                  <div class="form-group">
                    <input
                      class="
                        form-control form-control-solid
                        h-auto
                        py-7
                        px-6
                        rounded-lg
                        font-size-h6
                      "
                      type="email"
                      placeholder="Email"
                      name="email"
                      autocomplete="off"
                    />
                  </div>
                  <div class="form-group d-flex flex-wrap pb-lg-0">
                    <button
                      type="button"
                      id="kt_login_forgot_submit"
                      class="
                        btn btn-primary
                        font-weight-bolder font-size-h6
                        px-8
                        py-4
                        my-3
                        mr-4
                      "
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      id="kt_login_forgot_cancel"
                      class="
                        btn btn-light-primary
                        font-weight-bolder font-size-h6
                        px-8
                        py-4
                        my-3
                      "
                      @click="showForm('signin')"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
              <!--end::Forgot-->
            </div>
          </div>

          <div
            class="
              d-flex
              justify-content-lg-start justify-content-center
              align-items-end
              py-7 py-lg-0
            "
          >
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn plain @click.prevent="openPage(1)">
                  <p class="text-primary font-weight-bolder font-size-h5">
                    About us
                  </p>
                </v-btn>
                <v-btn plain @click.prevent="openPage(2)">
                  <p class="text-primary font-weight-bolder font-size-h5">
                    Contact us
                  </p>
                </v-btn>
                <v-btn plain @click.prevent="openFeaturePage(1)">
                  <p class="text-primary font-weight-bolder font-size-h5">
                    Forgot Password
                  </p>
                </v-btn>
                <!-- <v-btn plain @click.prevent="verifyEmail">
                <p class="text-primary font-weight-bolder font-size-h5">
                  Verify Email
                </p>
                </v-btn>-->
              </v-col>
            </v-row>
          </div>
          <p></p>
          <my-footer />
          <!--end::Content footer-->
        </v-col>
      </v-row>

      <v-dialog v-model="OpenPageFlag" persistent max-width="80%">
        <popup-page-content
          :addRecordPrompt="OpenPageFlag"
          :pageId="PageId"
          @hideAddRecordPrompt="hideAddRecordPrompt"
          v-if="OpenPageFlag"
        ></popup-page-content>
      </v-dialog>

      <v-dialog
        v-model="OpenForgetPasswordPageFlag"
        persistent
        width="auto"
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
      >
        <forget-password
          :addRecordPrompt="OpenForgetPasswordPageFlag"
          :pageId="PageId"
          @hideAddRecordPrompt="hideFeaturePage"
          v-if="OpenForgetPasswordPageFlag"
        ></forget-password>
      </v-dialog>

      <v-dialog
        v-model="OpenActivateAccountPageFlag"
        persistent
        width="auto"
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
      >
        <activate-account
          :addRecordPrompt="OpenActivateAccountPageFlag"
          :pageId="PageId"
          @hideAddRecordPrompt="hideFeaturePage"
          v-if="OpenActivateAccountPageFlag"
        ></activate-account>
      </v-dialog>

      <!--begin::Content footer-->
    </div>
    <!--end::Content-->
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import {
  LOGIN,
  LOGOUT,
  REGISTER,
  UPDATE_TOKEN,
} from "@/core/services/store/auth.module";
import Swal from "sweetalert2";
import companyConfig from "@/company_config.json";
import { version } from "/package.json";
import PopupPageContent from "@/view/pages/PopupPageContent.vue";
import ForgetPassword from "@/view/pages/ForgetPassword.vue";
import ActivateAccount from "@/view/pages/ActivateAccount.vue";
import common from "@/view/Common.vue";

export default {
  name: "login-1",
  mixins: [common],
  components: {
    PopupPageContent,
    ForgetPassword,
    ActivateAccount
  },
  data() {
    return {
      AppVersion: version,

      state: "signin",
      // Remove this dummy login info
      form: {
        email: "admin@demo.com",
        password: "demo",
      },
      valid: true,
      submitFlag: false,

      checkbox1: false,
      checkboxRules1: [(v) => !!v || "You must agree to continue!"],

      LoginColBgColor: "#6495ed",
      LoginLeftBgImage: "",
      LoginRightBgImage: "",

      isPasswordVisible: false,

      userLoginName: "",
      userLoginNameFlag: false,
      userLoginNameRules: [(v) => !!v || "Username is required"],

      userLoginPassword: "",
      userLoginPasswordFlag: false,
      userLoginPasswordRules: [(v) => !!v || "Password is required"],

      OpenPageFlag: false,
      PageId: "",

      OpenForgetPasswordPageFlag: false,
      OpenActivateAccountPageFlag: false,

      News: "",

      VersionInfo: "",
    };
  },
  watch: {},
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      var server_url = companyConfig.apiURL;
      var login_bg_image = server_url + "assets/images/login-bg-2.png";
      return login_bg_image;
      /*
      return (
        // process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
        process.env.BASE_URL + "media/logos/jci-login.png"
      );
      */
    },
  },
  mounted() {
    const signup_form = KTUtil.getById("kt_login_signup_form");
    const forgot_form = KTUtil.getById("kt_login_forgot_form");

    /*
    const signin_form = KTUtil.getById("kt_login_signin_form");

    this.fv = formValidation(signin_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "Username is required",
            },
          },
        },
        password: {
          validators: {
            notEmpty: {
              message: "Password is required",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

        this.fv.on("core.form.valid", () => {
      var email = this.form.email;
      var password = this.form.password;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send login request
        this.$store
          .dispatch(LOGIN, { email, password })
          // go to which page after successfully login
          .then(() => this.$router.push({ name: "dashboard" }))
          .catch(() => {});

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    });

    this.fv.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: "Please, provide correct data!",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false,
      });
    });
    */

    this.fv1 = formValidation(signup_form, {
      fields: {
        fullname: {
          validators: {
            notEmpty: {
              message: "Full name is required",
            },
          },
        },
        email: {
          validators: {
            notEmpty: {
              message: "Email is required",
            },
            emailAddress: {
              message: "The value is not a valid email address",
            },
          },
        },
        password: {
          validators: {
            notEmpty: {
              message: "Password is required",
            },
          },
        },
        cpassword: {
          validators: {
            notEmpty: {
              message: "Confirm password is required",
            },
            identical: {
              compare: function () {
                return signup_form.querySelector('[name="password"]').value;
              },
              message: "The password and its confirm are not the same",
            },
          },
        },
        agree: {
          validators: {
            notEmpty: {
              message: "You should agree terms and conditions",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv2 = formValidation(forgot_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "Email is required",
            },
            emailAddress: {
              message: "The value is not a valid email address",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv1.on("core.form.valid", () => {
      const email = this.$refs.remail.value;
      const password = this.$refs.rpassword.value;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signup_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send register request
        this.$store
          .dispatch(REGISTER, {
            email: email,
            password: password,
          })
          .then(() => this.$router.push({ name: "dashboard" }));

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    });

    this.fv1.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: "Please, provide correct data!",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false,
      });
    });
  },
  methods: {
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );
    },
    openPage(pageId) {
      console.log("openPage called " + pageId);
      // event.target.style.color = "salmon"
      this.PageId = pageId;
      this.OpenPageFlag = true;
    },
    hideAddRecordPrompt() {
      console.log("hideAddRecordPrompt called");
      this.OpenPageFlag = false;
    },
    verifyEmail() {
      console.log("verifyEmail called");
      var member = "1122500";
      var email = "msjothiraj@gmail.com";
      var code = "1234";
      var url = "/email-verification";
      var params = {
        member: member,
        email: email,
        code: code,
      };
      this.pageRedirect(params, url);
    },
    openFeaturePage(id) {
      console.log("openFeaturePage called id=" + id);
      switch (id) {
        case 1:
          this.OpenForgetPasswordPageFlag = true;
          break;

        case 2:
          this.OpenActivateAccountPageFlag = true;
          break;

        default:
          break;
      }
    },
    hideFeaturePage() {
      console.log("hideFeaturePage called");
      this.OpenForgetPasswordPageFlag = false;
      this.OpenActivateAccountPageFlag = false;
    },
    toast(title, message) {
      var append = false;
      var toaster = "b-toaster-top-right";
      var variant = title == "success" ? "success" : "danger";
      this.$bvToast.toast(`${message}`, {
        title: `${title}`,
        toaster: toaster,
        appendToast: append,
        variant: variant,
        solid: true,
      });
    },
    validateLogin() {
      console.log("validateLogin is called");
      var result = this.$refs.form.validate();
      console.log("result=" + result);
      var where = {
        name: this.userLoginName,
        password: this.userLoginPassword,
      };
      console.log("where=" + JSON.stringify(where));
      /*
      var flag = false;
      var token =
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiYjY1NmYzZGU1OTc0OTJiZTliMTFmYmJhZmUxOWE4OGYwNWEzNTVlZmY3YThhNDhhMTFlZjVjMzU0MDdkNTFkNzkyMTg3YmNmYjBjOTgxNDciLCJpYXQiOjE2MTU0NDY1NDMsIm5iZiI6MTYxNTQ0NjU0MywiZXhwIjoxNjQ2OTgyNTQzLCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.g3swWrcW1VFqkgcsCge1Ohor7UHNjiXLdesQlnEKQuUIJ2xAB8sV5XalS33P-qmjyFpkI4vATJ2HT6P0-ZyRrHU6fPyPCyK6B0zn-w3BR876SaHgqvKen25O0LZqRucaEjSDreKdUNN7NXvcBxKnHhFzNPM85tJmobmkqU7GU-SCoNlyhdndpx1UpLLOaHJXw0jskNiJdG-_E9UuyV7-5aGjzM31nLMPUI9tM-YbVmOHFwQYhBnoC_Qqp8ZZQgGSMYyYdSUD9xH8fCltb7oVDk3iAywrvkOwg_TX09f9E--mLXAk4UqiReE8tPKDAsDO6tEIlsCv_7fUT0TWJpICiq9ooVZDKdeON99Vki5oKkeqXESyE3UCJ4wKCDshgGtX34vc0EnS9D6jEuJh-GzClfYRdoVsf74Z7QyhI3wbSKyA5KQART0RU8COZacASTeBzCoLwEQgQz40It0VjGyNPlrHz2YY0QJe2bqjc8PKrawTgLVp5gEkBqRFGLxxC_xadpPHhuGGDMFvqvyPIqwihrKpv6rSe3OJA3TuKSZMwZcSqM4EFu-3UcjumIusn8fQ7XRCMHBptdFq4O2RV2nXzCdQaTB2ut0PMSYUxIiDP1Z-2NQjNx0duDEk7Z4K20uEFu0IIBqhfWgJ0yOg8VgxsnPkUfnBRCq6UccHrEuj6w0";

      this.$store
        .dispatch(LOGIN, {
          email: this.userLoginName,
          password: this.userLoginPassword,
        })
        .then(() => {
          console.log("page will be redirect to dashboard");
          result = true;
          this.$session.set("token", token);
          // this.$router.push({ name: "dashboard" });
          this.$router.push("/members/transfer");
          this.submitFlag = false;
          this.toast("success", error);
        })
        .catch(() => {
          console.log("some problem in store LOGIN");
          result = false;
          this.$session.set("token", token);
          this.$router.push("/members/transfer");
        });

      console.log("result=" + result);
      result = false;
      */

      if (result) {
        this.submitFlag = true;
        // this.resetMessageTxt();
        // this.ProgessStart = 1;

        var server_url = companyConfig.apiURL;
        var server_mac = companyConfig.MACAddress;
        var server_flag = companyConfig.Server;
        console.log(
          "server_url=" +
            server_url +
            ", server_mac=" +
            server_mac +
            ", server_flag=" +
            server_flag
        );

        var url = "api/login";
        var api_url = server_url + url;
        console.log("url=" + url + ", api_url=" + api_url);

        let thisIns = this;
        var output = "";
        var flag = 0;
        var records = {};

        this.$http({
          url: api_url,
          method: "POST",
          data: {
            where: where,
          },
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then(function (response) {
            thisIns.submitFlag = false;
            console.log("response=" + JSON.stringify(response));
            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            if (flag == 1) {
              records = response.data.records;
              console.log("records=" + JSON.stringify(records));

              var token = records.token;
              var Username = records.username;
              var RoleId = records.role_id;
              var LomId = records.lom_id;
              var ProfileUpdated = records.ProfileUpdated;
              var HomePageUrl = records.HomePageUrl;

              console.log(
                "Username=" +
                  Username +
                  ", RoleId=" +
                  RoleId +
                  ", LomId=" +
                  LomId +
                  ",ProfileUpdated=" +
                  ProfileUpdated +
                  ",HomePageUrl=" +
                  HomePageUrl +
                  ",token=" +
                  token
              );

              thisIns.toast("success", output);

              thisIns.$store.dispatch(UPDATE_TOKEN, token);

              // userRole = role <= 2 ? 'admin' : 'editor'

              thisIns.$session.start();
              thisIns.$session.set("token", token);
              thisIns.$session.set("user_id", records.user_id);
              thisIns.$session.set("CurrentYearId", records.Year);
              thisIns.$session.set(
                "CurrentJciYearName",
                records.CurrentJciYearName
              );
              thisIns.$session.set(
                "CurrentJciYearId",
                records.CurrentJciYearId
              );
              thisIns.$session.set("Username", Username);
              thisIns.$session.set("HomePageUrl", HomePageUrl);
              thisIns.$session.set("MemberId", records.member_id);
              thisIns.$session.set("MemeberFullname", records.member_fullname);
              thisIns.$session.set("DisplayName", records.display_name);
              thisIns.$session.set(
                "CurrentDesignation",
                records.current_designation
              );
              thisIns.$session.set("UserSymbol", records.user_symbol);
              thisIns.$session.set("EmailId", records.email_id);
              thisIns.$session.set("ProfilePic", records.ProfilePic);
              thisIns.$session.set("RoleId", RoleId);
              thisIns.$session.set("LomId", LomId);
              thisIns.$session.set("LomName", records.lom_name);
              thisIns.$session.set("ZoneId", records.zone_id);
              thisIns.$session.set("ZoneName", records.zone_name);
              thisIns.$session.set("PresidentName", records.president_name);
              thisIns.$session.set("NatconEndDate", records.natcon_end_date);

              if (ProfileUpdated) {
                thisIns.$router.push(HomePageUrl);
                // thisIns.$router.push("/members/transfer");
              } else {
                thisIns.$router.push("/quick-profile-update");
              }
              /*
              // RoleId => 1=Admin; 2=ZO; 3=LO; 4=Assessor; 5=Super Admin
              var UI = 1;
              var permission = thisIns.checkUserRights(UI, RoleId);
              console.log("permission=" + permission);
              if (permission) {
                thisIns.$router.push("/erp-home");
              } else {
                // thisIns.$router.push('/assessment/awards-list')
                thisIns.$router.push("/erp-home");
              }
              */
            } else {
              thisIns.password = "";
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.submitFlag = false;
          });
      }
    },
  },
  beforeMount() {
    console.log("beforeMount called");
    var server_url = companyConfig.apiURL;
    console.log("server_url=" + server_url);
    var LoginLeftBgImage = server_url + "assets/images/login-bg-left.png";
    var LoginRightBgImage = server_url + "assets/images/login-bg-right.png";
    console.log(
      "LoginLeftBgImage=" +
        LoginLeftBgImage +
        ", LoginRightBgImage=" +
        LoginRightBgImage
    );
    this.LoginLeftBgImage = LoginLeftBgImage;
    this.LoginRightBgImage = LoginRightBgImage;

    var url1 = "api/pages/news";
    var condition1 = {
      UserInterface: 1,
    };
    this.getPageDetails("News", "", url1, condition1);

    var url2 = "api/pages/version-info";
    var condition2 = {
      UserInterface: 1,
    };
    this.getPageDetails("VersionInfo", "", url2, condition2);
  },
};
</script>
